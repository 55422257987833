import React from "react";
import Item from "./Item";

class ItemlistComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { reload: false };
    this.containsObject = this.containsObject.bind(this);
  }

  componentDidMount() { }
  componentWillUnmount() { }

  containsObject = (obj) => {
    return this.props.isInWishlist(obj);
  };

  render() {
    return (
      <>
        {this.props.items.map((item, index) => {
          return this.props.isVeg ? (
            item.veg === true ? (
              <Item
                isVeg={this.props.isVeg}
                config={this.props.config}
                itemDetails={item}
                key={index}
                index={index}
                isInWishlist={this.props.isInWishlist}
                add={this.props.add}
                remove={this.props.remove}
                hotel={this.props.hotel}
                refresh={this.props.refresh}
              />
            ) : null
          ) : (
              <Item
                isVeg={this.props.isVeg}
                config={this.props.config}
                itemDetails={item}
                key={index + 'n'}
                index={index}
                isInWishlist={this.props.isInWishlist}
                add={this.props.add}
                remove={this.props.remove}
                hotel={this.props.hotel}
                refresh={this.props.refresh}
              />
            );
        })}
      </>
    );
  }
}
export default ItemlistComponent;
