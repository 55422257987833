import React from "react";
import Form from "muicss/lib/react/form";
import Input from "muicss/lib/react/input";
import Button from "muicss/lib/react/button";
import Row from "muicss/lib/react/row";
import Col from "muicss/lib/react/col";
import Container from "muicss/lib/react/container";
import * as restservices from "../../utils/restUtils";
import "./ContactForm.scss";


class DemoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      enquiry: "",
      message: "",
      showForm: true,
      orgnzn:'',
      startDate: new Date(),
    };
  }
  handleSubmit(e) {
    e.preventDefault();
    const { name, orgnzn, phone, email } = this.state;
    let requestBody = {
      name: name,
      organisation: orgnzn,
      email: email,
      phone_number: phone
    }
    this.sendContactDetails(requestBody);
    setTimeout(() => this.props.onClose(), 1500);
  }

  async sendContactDetails(requestBody) {
    const url = 'schedule_demo/';
    const postdata = await restservices.postData(url, requestBody);
    if (postdata && postdata.status === "ok") {
      // setShowInfo({
      //   showInfo: true,
      // });
      this.resetForm();
      console.log('contact details submitted');
    }
  };

  resetForm() {
    this.setState({
      name: "",
      phone: "",
      email: "",
      enquiry: "",
      message: "",
      captcha: false,
      showForm: false,
      orgnzn: '',
      startDate: new Date(),
    });
  }

  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value });
  };

  handleDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  render() {
    return (
      <div className="contact-form demo">
        {this.state.showForm ? (
          <>      
          <h2>Schedule A Demo</h2>
          <p>Your Information</p>
            <Form onSubmit={this.handleSubmit.bind(this)}>
            <Container>
              <Row>
                <Col xm="6">
                  <Input
                    label="Name"
                    type="text"
                    floatingLabel={true}
                    required={true}
                    onChange={this.handleChange.bind(this, "name")}
                  />
                </Col>
                <Col xm="6">
                  <Input
                    label="Organization"
                    type="text"
                    floatingLabel={true}
                    required={true}
                    onChange={this.handleChange.bind(this, "orgnzn")}
                  />
                </Col>
              </Row>
              <Row>
              <Col xm="6">
              <Input
                label="Email"
                type="email"
                floatingLabel={true}
                required={true}
                onChange={this.handleChange.bind(this, "email")}
              />
              </Col>
              <Col xm="6">
              <Input
                    label="Contact No"
                    type="number"
                    floatingLabel={true}
                    required={true}
                    onChange={this.handleChange.bind(this, "phone")}
                  />
              </Col>

              </Row>  


              <Button
              className="demo-btn"  
                variant="raised"
                color="primary"
                disabled={(this.state.name && this.state.phone)? false : true}
              >
                Schedule a demo
              </Button>
              {/* <Button variant="raised" onClick={this.props.onClose}>
                Cancel
              </Button> */}
              </Container>
            </Form>
          </>
        ) : (
          <div className="alert-info white">
            We will contact you shortly.<br/>
            Thank You.!
          </div>
        )}
      </div>
    );
  }
}

export default DemoForm;
