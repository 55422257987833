import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import ItemlistComponent from "../Itemlist/ItemlistComponent";
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(30),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


export default function ItemAccordion(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {props.subCategories.length > 0 && (
                props.subCategories.map((item, index) => {
                    return (
                        <Tabs key={index} item={item} {...props} addd={props.add} ws={props.isInWishlist} />
                    );
                })
            )}
        </div>
    );
}

function Tabs(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [count, setCount] = useState(0);
    const toggleCollpase = () => {
        setOpen(!open);
    }
    useEffect(() => {
        itemCount();
    },[props]);
    const itemCount = () => {
        if (!props.isVeg) {
            setCount(props.item.items.length);
        }
        else {
            let count = 0
            for (var i = 0; i < props.item.items.length; i++) {
                if (props.item.items[i].veg) {
                    count = count + 1;
                }
            }
            setCount(count);
        }
    }
    return (
        <Accordion expanded={open} onClick={toggleCollpase} className="accordion-head">
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.heading}
            >
                <Typography className={classes.heading}>{props.item.name}&nbsp;({count})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className="item-list">
                    {props.item.items &&
                        props.item.items.length ===
                        0 ? (
                            <div className="no-result">No items found</div>
                        ) : (
                            <>
                                <ItemlistComponent
                                    isVeg={props.isVeg}
                                    config={props.config}
                                    items={props.item.items}
                                    color={props.colorTheme}
                                    isInWishlist={props.ws}
                                    add={props.addd}
                                    remove={props.remove}
                                    showDetail={props.showDetail}
                                    hotel={props.hotel}
                                    refresh={props.refresh}
                                />
                            </>
                        )}
                </div>
            </AccordionDetails>
        </Accordion>
    );
}
