import ReactGA from 'react-ga';

export const storeData = (key, data) => {
    localStorage.setItem(
        key,
        JSON.stringify({
            data,
        })
    );
}

export const removeData = (key) => {
    localStorage.removeItem(key);
}

export const storeWishlist = (key, data) => {
    localStorage.setItem(
        key,
        JSON.stringify({
            items: data,
        })
    );
}

export const getData = (key) => {
    return JSON.parse(localStorage.getItem(key));
}

export const isInWishlist = (hotel, item) => {
    var i;
    var count = 0;
    const wishlist = getData(hotel);
    if (wishlist) {
        let items = wishlist.items;
        for (i = 0; i < items.length; i++) {
            if (items[i].id === item.id) {
                count++;
            }
        }
    }
    return count;
}

export const addToWishlist = (hotel, item, e) => {
    //GA  
    ReactGA.event({
        category: 'addToWishlist',
        action: item.slug,
        label: hotel
    });

    e.stopPropagation();
    let temp = getData(hotel);
    if (!temp) {
        let a = [];
        a.push(item);
        storeWishlist(hotel, a);
    } else {
        temp["items"].push(item);
        storeWishlist(hotel, temp["items"]);
    }
}

export const removeWithIdFromWishlist = (hotel, id, e) => {
    e.stopPropagation();
    const wishlist = getData(hotel);
    let tempList = wishlist.items;
    const index = findItemIndex(id, tempList);
    let item = tempList[index]
    tempList.splice(index, 1);
    storeWishlist(hotel, tempList);

    //GA  
    ReactGA.event({
        category: 'removeFromWishlist',
        action: item.slug,
        label: hotel
    });
}

export const removeFromWishlist = (hotel, id, e, count) => {
    e.stopPropagation();
    const wishlist = getData(hotel);
    let tempList = wishlist.items;
    for (var i = 0; i < count; i++) {
        const index = findItemIndex(id, tempList);
        tempList.splice(index, 1);
    }
    storeWishlist(hotel, tempList);
    return true;
}

const findItemIndex = (id, array) => {
    var i;
    if (array) {
        for (i = 0; i < array.length; i++) {
            if (array[i].id === id) {
                return i;
            }
        }
    }
    return false;
}
