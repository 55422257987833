import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

export async function getData(url) {
    try {
      const response = await axios.get(`${baseURL}${url}`);
      // console.log('response',response);
      return response.data ? response.data : undefined;
    } catch (error) {
      console.error(error);
    }
  }

  export async function postData(url, data) {
    try {
      const response = await axios.post(`${baseURL}${url}`, data);
      // console.log("ress", response);
      return response.data ? response.data : undefined;
    } catch (error) {
      console.error(error);
    }
  }
  