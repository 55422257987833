import React from 'react';
import DashboardHeader from './HeaderComponent';
import Faq from '../Faq/Faq';
import './Dashboard.scss';
import Contactform from '../ContactForm/ContactComponent';
import DashboardFooter from './DashboardFooter';
import Modal from '../Modal/ModalComponent';
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      openModal: false,
    };
  };

  toggleModal = () => {
    this.setState({ openModal: !this.state.openModal }, this.setScroll);
  };

  setScroll = () => {
    if (this.state.openModal) {
      document.body.style.overflow = "hidden";
    }
    else {
      document.body.style.overflow = "auto";
    }
  };

  render() {
    return (
      <div className="dashboard">
        <DashboardHeader />
        {this.state.openModal ? <Modal onClose={this.toggleModal} /> : ''}

        <div className="top-section">
          <div className="banner-content">
            <h1>Digitize your menu <br />& maximize revenue</h1>
            <h4>No app download needed <br />QR Code based solution </h4>   
            <button className="demo-btn" onClick={this.toggleModal}>schedule a demo</button>
          </div>
          <div className="banner-image">
            <img src="/bitmap.png" alt="scan" />
          </div>

        </div>
        <div className="section-2">
          <img className="left-triangle" src="/triangle-1.svg" alt="triangle" />
          <h2>The smart way to share your menu <span className="hide-mobile"><br /></span> with your customers</h2>
          <div className="flex align-center mobile-column justify-center">
            <img className="qr" src="/qr.png" alt="qr-code" />
            <div className="arrow-wrap">
              <img className="hide-mobile" src="/arrow-1.svg" alt="arrow" />
              <div className="arrow-txt-1">Scan QR Code <span className="hide-mobile"><br /></span>to view menu</div>

              <img className="mobile-only" src="/mobile-arrow-1.svg" alt="arrow" />
            </div>
            <img className="app-img" src="/app.png" alt="app" />
            <div className="flex double-arroe-wrap">
              <div className="arrow-wrap">
                <div className="arrow-txt-2">Order Dine-in</div>
                <img className="mobile-only" src="/arrow-mobile.svg" alt="arrow" />
                <img className="hide-mobile" src="/arrow-2.svg" alt="arrow" />
              </div>
              <div className="arrow-wrap">
                <img className="hide-mobile" src="/arrow-2.svg" alt="arrow" />
                <div className="arrow-txt-3">Order delivery/<span className="mobile-only"><br /></span>pickup</div>

                <img className="mobile-only" src="/arrow-mobile.svg" alt="arrow" />
              </div>
            </div>
            <div className="flex double-arroe-wrap">
              <img className="dine-img" src="/dine-in.png" alt="dine" />
              <img className="dine-img" src="/delivery.png" alt="delivery" />
            </div>
          </div>
          <div className="spacer-50"></div>
          {/*<p>A major concern for restaurants right now is the use of physical menus. With the current pandemic situation worldwide there is a new norm to use disposable menus among many restaurant associations worldwide. Our digital solution is meant to replace the traditional printed menus and eliminate all concerns related to viral transmission between in-house dining guests.</p>
          <p>Dynamic menu update has been quite a great marketing strategy adopted by top restaurants worldwide to keep up with the changing consumption patterns of the customers. Our solution gives customers the freedom to access beautiful photos and descriptions of your food and drink, all from their device. You can customize your digital restaurant menu and manage your orders from anywhere with all your data stored securely in the cloud.</p>
          <p>The QR Menu is one solution which can save time and money while providing your customers with a virus and bacteria-free alternative to paper menu.</p>
          
          
          
          <div >
          <h2>Our Features</h2><br />
          <p> high end, sophisticated digital menu system, It's easy to use and provides an error free ordering experience </p>
          </div>*/}
          <div class ="feature-main">
          <div >
          <h3>Contact Less <br/> Menus </h3>  <br/> 
          <p> Customer can straight away scan the QR code at the table during dine-in or at the enterance during take-away and order their favorite dishes. Long wait for waiter is no more a concern</p>
        </div>
        <div >
          <h3>Contact Less Drive Thru</h3><br />
          <p> Place your QR code on a curbside stand or drive thru. Your customers can open your active menu on their phone and order from inside their car.</p>
          </div>
        <div >
          <h3>Dynamic Menu Management</h3><br />
          <p> It's easy to change your menu and pricing at any time. We provide you flexibility to hide your menu items if items are not currently available </p>
          </div>

          <div >
          <h3>Online <br/> Ordering</h3><br />
          <p> Add a simple ‘order now’ button on your website or send your ordermydine link to customers. Then, your customers can place orders quickly from anywhere by using the link.</p>
          </div>

           

        </div>

        <div className="spacer-100">
         </div>
        </div>
        <div className="section-3">
          <div className="flex align-top mobile-column">
            <div className="img-wrap flex justify-right">
              <img className="app-img" src="/app.png" alt="app" />
            </div>
            <div >
              <h2>Contactless Digitalised Menu <br /> For All Foodpreneurs</h2>
              <h3>Dine-In, Delivery or Pick-Up Orders</h3>
              <p>We provide you high end, sophisticated digital menu and order handling system which is easy to use and provides an error free ordering experience. We will create you a space that speaks true to what your menus are.</p>
              <button className="demo-btn" onClick={this.toggleModal}>schedule a demo</button>
            </div>
          </div>
          <img className="right-triangle" src="/triangle-2.svg" alt="triangle" />
          <div className="spacer-100 hide-mobile"></div>
        </div>
        <div className="section-4 flex align-top">
          <div>
            <h2>Frequently Asked <br />Questions</h2>
            <p>Top Frequently Asked Questions</p>
            <Faq />
          </div>
          <div className="img-wrap flex hide-mobile">
            <img src="/faq.png" alt="faq" />
          </div>
        </div>
        <div className="spacer-100"></div>
        <div className="section-5">
          <Contactform />
        </div>
        <div className="section-6">
          <DashboardFooter />
        </div>
      </div>

    );
  }
}

export default Dashboard;
