import React, { useState, useEffect } from "react";
import AddButton from "../AddButton/AddButton";
import { getData, storeData } from "../../utils/localStorageUtils";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as restservices from "../../utils/restUtils";
import Loader from "../Loader/Loader";

function ItemDetail(props) {
  let history = useHistory();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const { slug, itemId } = useParams();
  const [tooltip, setTooltip] = useState('');
  const [config, setConfig] = useState({});
  const [itemDetail, setItemDetail] = useState({});
  const id = itemId.substring(itemId.lastIndexOf("-") + 1, itemId.length);
  const url = `${slug}/item/${id}`;

  useEffect(() => {
    if (location.state && location.state.item) {
      setItemDetail(location.state.item);
      const config = getData(slug + "-config");
      setConfig(config.data);
    }
    else {
      const loadItemDetail = async (url) => {
        setLoader(true);
        const getCall = await restservices.getData(url);
        if (getCall && getCall.status === "ok" && getCall.data) {
          setItemDetail(getCall.data);
          setLoader(false);
        }
        else {
          setLoader(false);
          setItemDetail(null);
        }
      };
      loadItemDetail(url);
      const config = getData(slug + "-config");

      if (config) {
        setConfig(config.data);
      }
      else {
        const loadHotelConfig = async (url) => {
          setLoader(true);
          const getCall = await restservices.getData(url);
          if (getCall.status === "ok" && getCall.data) {
            setConfig(getCall.data);
            storeData(slug + "-config", getCall.data);
            setLoader(false);
          }
        };
        loadHotelConfig(slug);
      }
    }
    return () => {
      storeData("from", 1);
    }
  }, [slug, itemId, url]);
  const showTooltip = (foodType) => {
    if (foodType === tooltip) {
      setTooltip('');
    }
    else {
      setTooltip(foodType);
    }
  }
  const backAction = location.state ? location.state.back : null;
  const description = { __html: itemDetail && itemDetail.description ? itemDetail.description : null };
  return (<>
    {loader && <Loader />}
    {itemDetail ? (
      <div id="detail-block" className="move-block dine-home">
        <div className="back-btn" id="back-btn" onClick={() => backAction ? history.goBack() : history.push(`/${slug}/menu`)}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" fill={config.theme_color} /></svg>
        </div>
        <img src={itemDetail.food_image ? itemDetail.food_image : null} alt="item" />
        <div className="details-wrap">
          <h3>{itemDetail.name}</h3>
          <h3 style={{ color: config.color }}>{config.currency}{itemDetail.price}</h3>
          <div className="item-icons-wrap">
            {itemDetail.recommended &&
              <div onClick={() => showTooltip('recommended')}>
                {tooltip === 'recommended' && <div className="tooltip">Recommended</div>}
                <svg xmlns="http://www.w3.org/2000/svg" width="91" height="91" viewBox="0 0 91 91">
                  <g fill="none" fillRule="evenodd">
                    <path fill="#DC7100" d="M77.672 13.328C69.073 4.73 57.66 0 45.5 0 33.34 0 21.927 4.73 13.328 13.328 4.73 21.927 0 33.34 0 45.5c0 12.16 4.73 23.573 13.328 32.172C21.927 86.27 33.34 91 45.5 91c12.16 0 23.573-4.73 32.172-13.328C86.27 69.073 91 57.66 91 45.5c0-12.16-4.73-23.573-13.328-32.172z" transform="matrix(-1 0 0 1 91 0)" />
                    <path fill="#FFF" fillRule="nonzero" d="M24 35.205h4.966c.357.002.714.043 1.063.121.188-.076.389-.116.592-.12h6.62V24.582c.005-4.185 3.396-7.576 7.581-7.581h2.35c.915 0 1.656.741 1.656 1.655v5.91c0 2.334.633 4.624 1.832 6.627l2.61 4.34 7.459 2.984h9.616c.914 0 1.655.74 1.655 1.655v21.517c0 .914-.741 1.655-1.655 1.655h-9.923l-.008.002h-6.417l-6.424 1.605c-.13.034-.265.05-.4.05H32.275c-.205-.002-.408-.043-.598-.12-.347.077-.702.117-1.057.118h-4.966c-2.741-.003-4.962-2.224-4.965-4.965.005-.856.234-1.695.664-2.435-1.242-.772-2.075-2.058-2.274-3.507-.199-1.449.258-2.91 1.246-3.99-1.722-1.87-1.722-4.749 0-6.62-1.322-1.45-1.661-3.544-.865-5.337.796-1.794 2.577-2.947 4.539-2.939zm38.069 24.828h6.62V41.826h-6.62v18.207zm-29.43-9.931c1.452 1.567 1.707 3.9.628 5.745 1.44.904 2.316 2.485 2.32 4.186-.006.566-.11 1.126-.306 1.657h11.688l6.423-1.605c.131-.034.266-.05.401-.05h4.966V41.292l-7.235-2.894c-.022-.008-.036-.027-.057-.037-.179-.076-.343-.183-.484-.317-.013-.013-.021-.028-.033-.041-.088-.088-.165-.185-.231-.29l-2.898-4.82c-1.507-2.518-2.303-5.396-2.304-8.33V20.31h-.695c-2.357.003-4.268 1.913-4.27 4.27V37.88c-.015 4.71 2.855 8.95 7.235 10.686.849.339 1.262 1.302.923 2.151-.34.85-1.303 1.262-2.152.923-5.414-2.154-9.048-7.299-9.269-13.122h-3.662c.639 1.708.257 3.63-.987 4.964 1.722 1.871 1.722 4.75 0 6.62zm-6.984 11.586h4.966c.914 0 1.655-.741 1.655-1.655s-.741-1.655-1.655-1.655h-4.966c-.914 0-1.655.74-1.655 1.655 0 .914.741 1.655 1.655 1.655zM24 55.068h4.966c.914 0 1.655-.742 1.655-1.656 0-.914-.741-1.655-1.655-1.655H24c-.914 0-1.655.741-1.655 1.655s.74 1.655 1.655 1.655zm0-6.621h4.966c.914 0 1.655-.741 1.655-1.656 0-.914-.741-1.655-1.655-1.655H24c-.914 0-1.655.741-1.655 1.655 0 .915.74 1.656 1.655 1.656zm0-6.621h4.966c.914 0 1.655-.741 1.655-1.655s-.741-1.655-1.655-1.655H24c-.914 0-1.655.74-1.655 1.655 0 .914.74 1.655 1.655 1.655z" transform="matrix(-1 0 0 1 91 0)" />
                  </g>
                </svg>
              </div>
            }

            {itemDetail.veg &&
              <div onClick={() => showTooltip('veg')}>
                {tooltip === 'veg' && <div className="tooltip">Veg</div>}
                <svg xmlns="http://www.w3.org/2000/svg" width="91" height="91" viewBox="0 0 91 91">
                  <g fill="none" fillRule="evenodd">
                    <path fill="#3EA105" d="M77.672 13.328C69.073 4.73 57.66 0 45.5 0 33.34 0 21.927 4.73 13.328 13.328 4.73 21.927 0 33.34 0 45.5c0 12.16 4.73 23.573 13.328 32.172C21.927 86.27 33.34 91 45.5 91c12.16 0 23.573-4.73 32.172-13.328C86.27 69.073 91 57.66 91 45.5c0-12.16-4.73-23.573-13.328-32.172z" transform="matrix(-1 0 0 1 91 0)" />
                    <path fill="#FFF" fillRule="nonzero" d="M74.917 39.018c-.084-.887-.787-1.59-1.674-1.675-.497-.047-12.253-1.085-19.1 5.763-1.484 1.485-2.597 3.201-3.43 4.983-1.374-5.623-3.816-10.289-7.168-13.642-4.132-4.133-10.256-6.883-17.71-7.952-5.546-.795-9.865-.398-10.046-.38-.887.084-1.59.787-1.674 1.674-.018.182-.415 4.501.38 10.05 1.069 7.455 3.818 13.581 7.95 17.714 4.132 4.133 10.256 6.883 17.71 7.952 2.745.394 5.189.495 6.98.495 1.622 0 3.987-.032 5.076-.032 3.807 0 11.776-.675 16.945-5.845 6.846-6.848 5.809-18.607 5.761-19.105zM34.31 43.685c-.726-.727-1.904-.727-2.63 0-.727.726-.727 1.904 0 2.63L45.61 60.25c-5.368-.18-14.627-1.417-20.535-7.327-7.041-7.043-7.44-18.908-7.336-23.184 4.275-.111 16.117.28 23.176 7.34 5.89 5.893 7.132 15.16 7.32 20.535L34.31 43.685zm32.216 11.807c-3.278 3.28-8.21 4.329-11.755 4.634l8.194-8.196c.726-.727.726-1.904 0-2.631-.726-.726-1.904-.726-2.63 0l-8.201 8.203c.3-3.54 1.346-8.471 4.64-11.765 4.266-4.268 11.334-4.76 14.499-4.748.011 3.166-.481 10.236-4.747 14.503z" transform="matrix(-1 0 0 1 91 0)" />
                  </g>
                </svg>
              </div>
            }

            {itemDetail.spicy &&
              <div onClick={() => showTooltip('spicy')}>
                {tooltip === 'spicy' && <div className="tooltip">Spicy</div>}
                <svg xmlns="http://www.w3.org/2000/svg" width="91" height="91" viewBox="0 0 91 91">
                  <g fill="none" fillRule="evenodd">
                    <path fill="#EC0000" d="M77.672 13.328C69.073 4.73 57.66 0 45.5 0 33.34 0 21.927 4.73 13.328 13.328 4.73 21.927 0 33.34 0 45.5c0 12.16 4.73 23.573 13.328 32.172C21.927 86.27 33.34 91 45.5 91c12.16 0 23.573-4.73 32.172-13.328C86.27 69.073 91 57.66 91 45.5c0-12.16-4.73-23.573-13.328-32.172z" transform="matrix(-1 0 0 1 91 0)" />
                    <g fillRule="nonzero" stroke="#FFF" strokeWidth="2">
                      <path d="M13.238 13.794c.255-4.34 1.346-7.84 3.128-11.364.42-.83.08-1.839-.76-2.254-.838-.411-1.858-.078-2.28.752-1.969 3.894-3.23 7.917-3.487 12.885-1.225.175-2.377.587-3.46 1.128-.23 3.037 2.192 5.423 5.07 5.423 2.948 0 5.387-2.486 5.053-5.59-1.031-.475-2.124-.81-3.264-.98z" transform="matrix(-1 0 0 1 91 0) scale(-1 1) rotate(-26 20.206 178.439)" />
                      <path d="M38.09 49.137h.001c-15.308-3.048-15.297-12.998-15.284-23.533 0-3.447-.716-6.367-3.443-9.059-.791 3.956-4.177 6.577-7.96 6.577-3.597 0-6.975-2.377-7.895-6.344C1.372 18.974 0 21.998 0 25.325c0 8.032 4.491 16.808 11.722 22.9C17.778 53.327 24.95 56 32.365 56c1.882 0 3.78-.172 5.678-.52 1.566-.288 2.67-1.584 2.684-3.15.015-1.568-1.07-2.882-2.637-3.193z" transform="matrix(-1 0 0 1 91 0) scale(-1 1) rotate(-26 20.206 178.439)" />
                    </g>
                  </g>
                </svg>
              </div>
            }

            {itemDetail.nuts &&
              <div onClick={() => showTooltip('nuts')}>
                {tooltip === 'nuts' && <div className="tooltip">Nuts</div>}
                <svg xmlns="http://www.w3.org/2000/svg" width="91" height="91" viewBox="0 0 91 91">
                  <g fill="none" fillRule="evenodd">
                    <path fill="#50381F" d="M77.672 13.328C69.073 4.73 57.66 0 45.5 0 33.34 0 21.927 4.73 13.328 13.328 4.73 21.927 0 33.34 0 45.5c0 12.16 4.73 23.573 13.328 32.172C21.927 86.27 33.34 91 45.5 91c12.16 0 23.573-4.73 32.172-13.328C86.27 69.073 91 57.66 91 45.5c0-12.16-4.73-23.573-13.328-32.172z" transform="matrix(-1 0 0 1 91 0)" />
                    <g>
                      <g fill="#FFD89E" opacity=".106">
                        <path d="M28.925 7.36C30.81.35 8.488-6.728 3.458 11.974c-2.272 8.45 9.834 12.935 12.324 3.676 2.578-9.587 12.188-4.74 13.143-8.29zM42.074 18.693c-4.834-8.34-17.765-2.441-12.469 6.7 5.485 9.463-5.786 13.144-3.754 16.65 4.01 6.92 26.922-4.886 16.223-23.35zM30.677 54.801c0-4.82-13.82-1.93-13.82-14.94C16.858 27.293 0 28.88 0 40.348 0 65.732 30.677 64.315 30.677 54.8z" transform="matrix(-1 0 0 1 91 0) translate(23.239 14) translate(1.91 1.91)" />
                      </g>
                      <path fill="#FFF" fillRule="nonzero" d="M28.134 51.486c-4.045-1.5-8.63-3.201-8.63-10.618 0-3.207-.977-5.882-2.825-7.737-1.649-1.653-3.914-2.564-6.379-2.564-2.587 0-5.152 1.02-7.036 2.801C1.774 34.775 0 37.305 0 41.37c0 7.4 2.452 13.451 7.089 17.499C10.826 62.129 15.98 64 21.23 64c7.071 0 12.203-3.273 12.203-7.783 0-2.766-2.694-3.765-5.299-4.731zm.19 8.343c-1.829 1.044-4.348 1.618-7.094 1.618-4.658 0-9.215-1.645-12.503-4.515-4.065-3.547-6.213-8.929-6.213-15.562 0-5.359 4.011-8.25 7.786-8.25 1.799 0 3.437.649 4.612 1.828 1.36 1.364 2.079 3.41 2.079 5.92 0 9.202 6.187 11.497 10.28 13.016 2.262.838 3.648 1.4 3.648 2.333 0 1.762-1.411 2.936-2.595 3.612z" transform="matrix(-1 0 0 1 91 0) translate(23.239 14)" />
                      <path fill="#FFF" fillRule="nonzero" d="M7.27 44.12c-.082-.707-.705-1.211-1.391-1.125-.687.084-1.176.725-1.094 1.43.335 2.872 1.162 5.4 2.457 7.511.238.389.645.601 1.06.601.228 0 .459-.064.666-.198.585-.38.76-1.175.391-1.776-1.096-1.786-1.799-3.955-2.09-6.444zM47.421 25.97c-.134-.688-.796-1.135-1.477-.999s-1.124.803-.99 1.49c1.786 9.115-4.926 15.213-9.872 17.088-3.142 1.19-5.765.923-6.684-.68-.313-.547.422-1.498 1.669-3 2.387-2.877 5.993-7.223 2.116-13.996-1.546-2.702-1.44-5.303.294-7.135 1.84-1.947 4.92-2.45 7.323-1.199.617.322 1.375.077 1.694-.545.318-.622.077-1.387-.54-1.709-3.373-1.756-7.703-1.04-10.297 1.702-2.521 2.666-2.765 6.462-.651 10.154 2.997 5.237.413 8.352-1.868 11.1-1.54 1.855-3.132 3.774-1.918 5.896 1.003 1.752 2.932 2.67 5.334 2.669 1.348 0 2.846-.29 4.412-.882 3.488-1.323 6.719-3.897 8.861-7.062 2.598-3.838 3.495-8.295 2.594-12.893zM10.92 22.925c.617 0 1.23-.075 1.822-.229 2.61-.68 4.507-2.717 5.34-5.739 1.392-5.043 4.845-5.34 8.184-5.628 2.215-.191 4.506-.388 5.099-2.539.477-1.728-.139-3.533-1.733-5.082-1.52-1.477-3.814-2.626-6.458-3.236-2.837-.655-5.77-.628-8.481.077-2.653.691-4.964 1.992-6.866 3.867-2.14 2.11-3.699 4.897-4.634 8.286-.82 2.972-.083 5.848 2.022 7.893 1.541 1.497 3.646 2.33 5.705 2.33zm-5.292-9.589c1.939-7.028 6.355-9.547 9.717-10.422 1.214-.316 2.453-.457 3.666-.457 3.673 0 7.104 1.298 8.838 2.982.66.64 1.381 1.631 1.081 2.718-.136.497-1.473.612-2.886.733-3.404.293-8.548.736-10.396 7.433-.599 2.173-1.83 3.559-3.558 4.009-1.716.446-3.715-.13-5.092-1.468-.853-.828-2.165-2.647-1.37-5.528z" transform="matrix(-1 0 0 1 91 0) translate(23.239 14)" />
                    </g>
                  </g>
                </svg>
              </div>
            }

          </div>
          <div className="description" dangerouslySetInnerHTML={description} />
          <AddButton hotel={slug} config={config} itemDetails={itemDetail} color={config.color} />
        </div>
      </div>
    ) : <div className="no-result">
        <p>No items found</p>
        <a href={`/${slug}`}>Back to home</a>
      </div>
    }
  </>
  );

}
export default ItemDetail;