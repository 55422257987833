import React, { useState, useEffect } from "react";
import * as restservices from "../../utils/restUtils";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ItemlistComponent from "../Itemlist/ItemlistComponent";
import Loader from "../Loader/Loader";
import ItemAccordion from "../ItemAccordion/ItemAccordion";
import { storeData, getData, isInWishlist, addToWishlist, removeData, removeWithIdFromWishlist } from "../../utils/localStorageUtils";

export const DineMenu = (props) => {
  const params = new URLSearchParams(useLocation().search);
  let history = useHistory();

  const [currentCategory, setCurrentCategory] = useState(0);
  const [menu, setmenu] = useState([]);
  const [wishlist, setWishList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [colorTheme, setColorTheme] = useState("#dc7100");
  const [reload, setReload] = useState(false);
  const [hotelConfig, setHotelConfig] = useState({});
  const [isVeg, setVegOnly] = useState(false);
  const { slug } = useParams();
  const url = `${slug}/menu/`;
  const things = {};
  things.thing = [];

  useEffect(() => {
    const loadHotelConfig = async (url) => {
      setLoader(true);
      const getCall = await restservices.getData(url);
      if (getCall.status === "ok" && getCall.data) {
        setHotelConfig(getCall.data);
        storeData(slug + "-config", getCall.data);
        setColorTheme(getCall.data.text_color);
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      }
    };
    const loadMenu = async (url) => {
      setLoader(true);
      const getCall = await restservices.getData(url);
      if (getCall.data !== undefined && getCall.data.categories.length > 0) {
        setmenu(getCall.data);
        storeData(slug + "-menu", getCall.data);
        let temp = getData(slug);
        if (temp) {
          setWishList(temp["items"]);
        }
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      }
    };

    const getTableDetails = async () => {
      const table_code = params.get("table");
      if (table_code) {
        const getCall = await restservices.getData("tables/" + table_code);
        // console.log("getcall",getCall);
        if (getCall !== undefined) {
          storeData(slug + "-table", getCall.data);
        }
        else {
          removeData(slug + "-table");
        }
      }
      else {
        removeData(slug + "-table");
      }
    };
    if (history.action === "POP") {
      const from = getData("from");
      if (from && from.data) {
        const menu = getData(slug + "-menu");
        const wishlist = getData(slug);
        const hotelConfig = getData(slug + "-config");
        const category = getData(slug + "-category");
        if (menu) {
          setLoader(true);
          setmenu(menu.data);
          setLoader(false);
        }
        if (wishlist) {
          setWishList(wishlist["items"]);
        }
        if (hotelConfig) {
          setHotelConfig(hotelConfig.data);
          setColorTheme(hotelConfig.data.theme_color);
        }
        if (category) {
          setCurrentCategory(category.data);
        }
        removeData("from");
      }
      else {
        loadHotelConfig(slug);
        loadMenu(url);
        getTableDetails();
      }
    }
    else {
      loadHotelConfig(slug);
      loadMenu(url);
      getTableDetails();
    }
  }, [slug, url]);

  useEffect(() => {
    things.thing = wishlist;
    setReload(!reload);
  }, [wishlist]);


  const loadItems = (val) => {
    setCurrentCategory(val);
    storeData(slug + "-category", val);
  };
  const showWishList = () => {
    history.push(`/${slug}/cart`);
  };
  const refreshCount = () => {
    let temp = getData(slug);
    setWishList(temp["items"]);
  }

  // const setUniqueItems = () => {
  //   const uniqueArray = things.thing.filter((thing, index) => {
  //     const _thing = JSON.stringify(thing);
  //     return (
  //       index ===
  //       things.thing.findIndex((obj) => {
  //         return JSON.stringify(obj) === _thing;
  //       })
  //     );
  //   });
  // };

  const vegOnly = () => {
    setVegOnly(!isVeg);
  };

  return (
    <>
      {loader && <Loader />}
      {menu.length === 0 ? (
        <div className="no-result">
          <p>No categories or items found</p>
          <a href="/">Back to home</a>
          <Footer />
        </div>
      ) : (
          <>
            <div className="dine-home">
              <Header
                logo={hotelConfig.restaurant_logo}
                phone={hotelConfig.phone_number}
                slug={slug}
              />
              <div
                className="dine-block"
                style={{
                  marginTop: "70px",
                }}
              >

                <div className={"main-block"}
                >
                  <div className="category-list-wrap">
                    <div className="category-list" id="category-list">
                      {menu &&
                        menu.categories.map((item, index) => {
                          return (
                            <div
                              style={{
                                borderBottom:
                                  currentCategory === index
                                    ? "2px solid " + colorTheme
                                    : "2px solid transparent",
                              }}
                              className="category-item"
                              key={index}
                              onClick={() => loadItems(index)}
                            >
                              <img
                                src={item.category_image}
                                alt="category"
                              />
                              <div
                                style={{
                                  color:
                                    currentCategory === index
                                      ? colorTheme
                                      : "#575864",
                                }}
                              >
                                {item.name}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div>
                    {hotelConfig && hotelConfig.veg_filter && (
                      <div
                        className="veg-only"
                        style={{
                          color: isVeg ? "#4ac503" : "#b8b8b8",
                        }}
                        onClick={vegOnly}
                      >
                        veg only{" "}
                        <span
                          className={isVeg ? "active" : null}
                          style={{
                            backgroundColor: isVeg ? "#4ac503" : "#b8b8b8",
                          }}
                        ></span>
                      </div>
                    )}
                  </div>

                  <div id="list">
                    <h3>{menu.categories[currentCategory].name}</h3>
                    <p>{menu.categories[currentCategory].description}</p>

                    <div className="item-list">
                      {menu &&
                        menu.categories[currentCategory].items.length ===
                        0 ? (
                          menu.categories[currentCategory].subcategories.length === 0 &&
                          <div className="no-result">No items found</div>
                        ) : (
                          <>
                            <ItemlistComponent
                              isVeg={isVeg}
                              config={hotelConfig}
                              items={menu.categories[currentCategory].items}
                              color={colorTheme}
                              isInWishlist={isInWishlist}
                              add={addToWishlist}
                              remove={removeWithIdFromWishlist}
                              currency={hotelConfig.currency}
                              hotel={slug}
                              refresh={refreshCount}

                            />
                          </>
                        )}
                    </div>
                    {menu.categories[currentCategory].subcategories && (
                      <ItemAccordion
                        subCategories={menu.categories[currentCategory].subcategories}
                        isVeg={isVeg}
                        config={hotelConfig}
                        items={menu.categories[currentCategory].items}
                        color={colorTheme}
                        isInWishlist={isInWishlist}
                        add={addToWishlist}
                        remove={removeWithIdFromWishlist}
                        hotel={slug}
                        refresh={refreshCount}
                      />
                    )
                    }

                  </div>
                </div>
                <Footer />
              </div>
              <div
                className="wishlist-icon"
                onClick={() => showWishList()}
              >
                {hotelConfig.entity_type === "restaurant" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 168 168"
                  >
                    <defs>
                      <filter
                        id="prefix__a"
                        width="116%"
                        height="116%"
                        x="-8%"
                        y="-8%"
                        filterUnits="objectBoundingBox"
                      >
                        <feOffset
                          dy="2"
                          in="SourceAlpha"
                          result="shadowOffsetOuter1"
                        />
                        <feGaussianBlur
                          in="shadowOffsetOuter1"
                          result="shadowBlurOuter1"
                          stdDeviation="2"
                        />
                        <feColorMatrix
                          in="shadowBlurOuter1"
                          result="shadowMatrixOuter1"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.200202142 0"
                        />
                        <feMerge>
                          <feMergeNode in="shadowMatrixOuter1" />
                          <feMergeNode in="SourceGraphic" />
                        </feMerge>
                      </filter>
                    </defs>
                    <g
                      fill="none"
                      fillRule="evenodd"
                      filter="url(#prefix__a)"
                      transform="matrix(-1 0 0 1 159 9)"
                    >
                      <path
                        fill="#FFF"
                        d="M128.03 21.97C113.857 7.797 95.044 0 75 0 54.956 0 36.143 7.797 21.97 21.97 7.797 36.143 0 54.956 0 75c0 20.044 7.797 38.857 21.97 53.03C36.143 142.203 54.956 150 75 150c20.044 0 38.857-7.797 53.03-21.97C142.203 113.857 150 95.044 150 75c0-20.044-7.797-38.857-21.97-53.03z"
                      />
                      <path
                        fill={colorTheme}
                        fillRule="nonzero"
                        d="M75.372 24c-4.168 0-7.56 3.351-7.56 7.47 0 1.281.328 2.487.905 3.542-29.219 3.25-52.08 27.253-53.141 56.741-.959.742-1.576 1.897-1.576 3.195C14 97.186 15.836 99 18.1 99h114.8c2.264 0 4.1-1.814 4.1-4.052 0-1.298-.617-2.453-1.578-3.195-1.062-29.57-24.047-53.623-53.381-56.768.568-1.047.89-2.244.89-3.514 0-4.12-3.39-7.471-7.56-7.471zm.128 15.701c29.084 0 52.88 22.376 54.723 50.658l.031.537h-52.98C75.748 75.591 62.687 63.633 46.8 63.633c-8.494 0-16.18 3.418-21.728 8.938 8.184-18.88 26.906-32.237 48.855-32.848l.666-.014.907-.008zm-28.7 27.73c13.763 0 25.1 10.262 26.608 23.465h-52.66l.031-.565c.097-1.474.254-2.933.468-4.373 3.33-10.482 13.082-18.153 24.7-18.513l.41-.01.443-.003zm28.7-24.184c-1.132 0-2.05.907-2.05 2.026 0 1.119.918 2.026 2.05 2.026 23.106 0 42.66 16.33 46.495 38.83.17.99 1.037 1.69 2.02 1.69.112 0 .227-.01.342-.029 1.117-.186 1.87-1.231 1.681-2.334-1.987-11.66-8.128-22.34-17.29-30.077C99.48 47.556 87.673 43.247 75.5 43.247zm-.256-15.448c2.12 0 3.844 1.704 3.844 3.798 0 1.262-.626 2.382-1.586 3.073-.665-.023-1.332-.034-2.002-.034-.833 0-1.662.017-2.487.05-.975-.686-1.613-1.815-1.613-3.089 0-2.094 1.724-3.798 3.844-3.798z"
                      />
                    </g>
                  </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={hotelConfig.theme_color} width="50px" height="50px"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" /></svg>
                  )}
                {wishlist.length > 0 ? (
                  <div className="badge" style={{ backgroundColor: colorTheme }}>
                    {reload ? wishlist.length : wishlist.length}
                  </div>
                ) : null}
              </div>
            </div>
          </>
        )}
    </>
  );
};
export default DineMenu;
