import React, { useEffect } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import DineMenu from './Components/DineMenu/DineMenu';
import Dashboard from './Components/Dashboard/Dashboard';
import SearchResults from './Components/SearchItems/SearchItems';
import ItemDetail from "./Components/ItemDetails/ItemDetail";
import WishlistComponent from './Components/Wishlist/WishlistComponent';
import ReactGA from 'react-ga';

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <Router>
      <>
        <Switch>
          <Route path="/:slug/menu">
            <DineMenu />
          </Route>
          <Route path="/:slug/search">
            <SearchResults />
          </Route>
          <Route path="/:slug/item/:itemId">
            <ItemDetail />
          </Route>
          <Route path="/:slug/cart">
            <WishlistComponent />
          </Route>
          <Route path="/:slug/checkout">
            <WishlistComponent />
          </Route>
          <Route path="/:slug">
            <DineMenu />
          </Route>
          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
      </>
    </Router>

  );
}

export default App;
