import React from 'react';
import { useHistory } from 'react-router';


function Header(props) {
  let history = useHistory();
  return (
    <div className="sm-header">
      {props.phone && <a href={"tel:" + props.phone}>
        <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.152 15.3778L16.5747 13.0571C16.2733 12.8596 15.9239 12.7552 15.5639 12.7552C14.9264 12.7552 14.3411 13.0758 13.9975 13.6132L13.5244 14.3549C12.6875 14.0655 11.3612 13.425 9.96926 12.0331C8.57733 10.6409 7.93662 9.31479 7.64718 8.47821L8.38914 8.00454C8.81099 7.7352 9.10096 7.31757 9.20563 6.82804C9.31004 6.34037 9.21752 5.84318 8.94659 5.43004L6.62505 1.85112C6.28037 1.31824 5.69648 1 5.0629 1C4.71083 1 4.36668 1.10018 4.06667 1.29049L3.19467 1.84715C2.80718 2.10513 2.49105 2.39747 2.25421 2.71651C2.24628 2.72629 2.23862 2.73633 2.23122 2.74664C-0.262136 6.25182 1.1356 12.1732 5.48238 16.52C8.29002 19.3279 11.8771 21.0042 15.0776 21.0044H15.0781C16.6772 21.0044 18.1215 20.5778 19.256 19.7711C19.2644 19.7648 19.2739 19.7576 19.2829 19.7502C19.6033 19.5131 19.8967 19.1962 20.161 18.7989L20.7129 17.9343C21.2569 17.0764 21.0047 15.9295 20.152 15.3778ZM19.7994 17.3531L19.2544 18.2077C19.0614 18.4968 18.8505 18.7257 18.6269 18.8885C18.6211 18.8928 18.6152 18.8973 18.6094 18.902C17.6621 19.5692 16.442 19.9218 15.0781 19.9218C12.1578 19.9215 8.85725 18.3636 6.24786 15.7545C2.27853 11.7851 0.928108 6.47729 3.09925 3.39423C3.10427 3.38789 3.10903 3.38181 3.11379 3.3752C3.27661 3.15158 3.50552 2.94066 3.78596 2.75378L4.64792 2.20399C4.99814 1.98143 5.49111 2.09165 5.71631 2.43976L8.03997 6.02186C8.1523 6.19314 8.19037 6.39905 8.14702 6.60126C8.1034 6.80558 7.98234 6.97977 7.80657 7.0921L6.71624 7.78806C6.51588 7.916 6.42231 8.15944 6.48575 8.3886C6.78126 9.45594 7.49255 11.0873 9.20378 12.7986C10.9147 14.5098 12.5464 15.2211 13.6137 15.5166C13.84 15.579 14.0863 15.4862 14.2143 15.2861L14.91 14.196C15.1331 13.8471 15.6284 13.7319 15.9831 13.964L19.5636 16.2866C19.9199 16.517 20.0257 16.9963 19.7994 17.3531Z" strokeWidth="0.25" style={{ fill: "rgb(87, 89, 98)", stroke: "rgb(87, 89, 98)" }}></path>
        </svg>
      </a>
      }
      <img src={props.logo} alt="logo" className="logo" />
      <img src='/search-24px.svg' className="search" alt="search" onClick={() => history.push(`/${props.slug}/search`)} />
    </div>

  );
}

export default Header;
