import React, { useState, useEffect } from "react";
import * as restservices from "../../utils/restUtils";
import ItemlistComponent from "../Itemlist/ItemlistComponent";
import { getData, isInWishlist, storeData } from "../../utils/localStorageUtils";
import { useHistory, useParams } from "react-router-dom";

function SearchResults(props) {
  let history = useHistory();
  const [searchResult, setSearchResult] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [config, setConfig] = useState({});
  const [noResultMsg, setNoResultMsg] = useState("Search for your favorite food.");

  const { slug } = useParams();
  const url = `${slug}/food/?search=`;
  const getInput = (e) => {
    loadResults(e.target.value);
  };

  useEffect(() => {
    const config = getData(slug + "-config");
    setConfig(config.data);
    return () => {
      storeData("from", 1);
    }
  }, [slug]);

  const loadResults = async (query) => {
    if (query.length > 1) {
      setLoader(true);
      const getCall = await restservices.getData(url + query);
      if (getCall.data !== undefined && getCall.data) {
        setSearchResult(getCall.data);
        setLoader(false);
      }
      else {
        setLoader(false);
        setNoResultMsg("No results found.");
      }
    }
  };
  return (
    <div className="search-page dine-home">
      <div className="back-btn" id="back-btn" onClick={() => history.goBack()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"
            fill={config.theme_color}
          />
        </svg>
      </div>
      <input
        className="search-input"
        type="text"
        onChange={(e) => getInput(e)}
        placeholder="Search for food"
      />
      <div className="search-list-block">
        {isLoader ? (
          <div className="loader p-absolute">
            <img src="/loader-1.jpg" alt="loader" />
          </div>
        ) : (
            <>
              {searchResult.length > 0 ? (
                <ItemlistComponent
                  config={config}
                  items={searchResult}
                  isInWishlist={isInWishlist}
                  add={props.add}
                  remove={props.remove}
                  showDetail={props.showDetail}
                  currency={config.currency}
                  hotel={slug}
                />
              ) : (
                  <div className="no-result">{noResultMsg}</div>
                )}
            </>
          )}
      </div>
    </div>
  );
}

export default SearchResults;
