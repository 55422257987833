import React from 'react';


function Footer() {
  return (
    <div className="footer">
      <div>Powered by &nbsp;</div> <a href="/" target="_blank"><img src="/logo.svg" alt="footer logo" /></a></div>

  );
}

export default Footer;
