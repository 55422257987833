import React from "react";
import Form from "muicss/lib/react/form";
import Input from "muicss/lib/react/input";
import Textarea from "muicss/lib/react/textarea";
import Button from "muicss/lib/react/button";
import Row from "muicss/lib/react/row";
import Col from "muicss/lib/react/col";
import Container from "muicss/lib/react/container";
import "./ContactForm.scss";
import * as restservices from "../../utils/restUtils";;


class Contactform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      message: "",
      showForm: true,
      orgnzn: '',
    };
  }
  handleSubmit(e) {
    e.preventDefault();
    const { name, phone, email,  message, orgnzn } = this.state;
    let requestBody = {
      name: name,
      organisation: orgnzn,
      email: email,
      query: message,
      phone_number: phone,
    }
    this.sendContactDetails(requestBody);
    this.resetForm();
  }

  async sendContactDetails(requestBody) {
    const url = 'contact_us/';
    const postdata = await restservices.postData(url, requestBody);
    if (postdata && postdata.status === "ok") {
      // setShowInfo({
      //   showInfo: true,
      // });
      this.resetForm();
      console.log('contact details submitted');
    }
  };

  resetForm() {
    this.setState({
      name: "",
      phone: "",
      email: "",
      message: "",
      showForm: false,
      orgnzn: '',
    });
  }

  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value });
  };

  render() {
    return (
      <div className="contact-form">
        {this.state.showForm ? (
          <>
            <Container>
              <Row>
                {/* <Col xs="12"> */}
                  <h2>Contact Us</h2>
                  <p>Want to get in touch? We'd love to hear from you. Here's how you can reach us...</p>
                {/* </Col> */}
              </Row>
            </Container>
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <Container>
                <Row>
                  <Col xm="6">
                    <Input
                      label="Name"
                      type="text"
                      floatingLabel={true}
                      required={true}
                      onChange={this.handleChange.bind(this, "name")}
                    />
                  </Col>
                  <Col xm="6">
                    <Input
                      label="Organization"
                      type="text"
                      floatingLabel={true}
                      required={true}
                      onChange={this.handleChange.bind(this, "orgnzn")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xm="6">
                    <Input
                      label="Email"
                      type="email"
                      floatingLabel={true}
                      required={true}
                      onChange={this.handleChange.bind(this, "email")}
                    />
                  </Col>
                  <Col xm="6">
                    <Input
                      label="Contact No"
                      type="number"
                      floatingLabel={true}
                      required={true}
                      onChange={this.handleChange.bind(this, "phone")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xm="6">
                    <Textarea
                      label="Your Query"
                      floatingLabel={true}
                      required={true}
                      rows="2"
                      onChange={this.handleChange.bind(this, "message")}
                    />
                  </Col>
                </Row>

                <Button
                  className="demo-btn"
                  variant="raised"
                  color="primary"
                  disabled={(this.state.name && this.state.phone) ? false : true}
                >
                  send message
              </Button>
              </Container>
            </Form>
          </>
        ) : (
            <div className="alert-info">
              Thank You.! <br />
               We will contact you shortly.
            </div>
          )}
      </div>
    );
  }
}

export default Contactform;
