import React from 'react';
import './Modal.scss';
import DemoForm from '../ContactForm/DemoForm';

class Modal extends React.Component {
    render() {
        return <div className="modal-container" onClick={this.props.onClose}>
            <div className="modal-content" onClick={(e)=> e.stopPropagation()}>
                <img className="close-btn" src="/close-modal.svg" alt="close" onClick={this.props.onClose} />
                <DemoForm onClose={this.props.onClose} />
            </div>
        </div>
    }
}

export default Modal;