import React from "react";
import * as constants from '../../Constants';

class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.state = { reload: false, openFaq: 0 };
        this.remove = this.remove.bind(this);
        this.add = this.add.bind(this);
        this.showFaq = this.showFaq.bind(this);
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }
    remove = (index, e) => {
        this.setState({
            reload: !this.state.reload
        });
        this.props.remove(index, e);
    }
    add = (index, e) => {
        this.setState({
            reload: !this.state.reload
        });
        this.props.add(index, e);
    }
    showFaq = (index) => {
        this.setState({ openFaq: index });
    }

    render() {
        return (
            <>
                {
                    constants.faqData.map((item, index) => {
                        return (
                            <div key={index} className="faq" onClick={() => this.showFaq(index)}>
                                <div className="faq-btn">
                                    {this.state.openFaq === index ?
                                        <img src="/minus-small.svg" alt="" />
                                        : <img src="/plus-small.svg" alt="" />
                                    }
                                </div>
                                <div className="question">{item.q}</div>

                                {this.state.openFaq === index && 
                                <div className="answer">
                                    {item.a.length === 1 ?  item.a[0] : 
                                    (   
                                    <ul>
                                        {item.a.map((answer, index)=> {
                                            return (
                                               <li key={index}>
                                                   {answer}
                                               </li>
                                            );
                                        })}
                                    </ul> 
                                    )}
                                    </div>}

                                    {/* ) }  */}
                            </div>
                        );
                    })
                }
            </>
        );
    }

}
export default Faq;