import React from "react";
import "./AddButton.scss";
import ButtonIcon from "./ButtonIcon";
import { isInWishlist, addToWishlist, removeWithIdFromWishlist } from "../../utils/localStorageUtils";

class AddButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { reload: false, count: 0 };
    this.remove = this.remove.bind(this);
    this.add = this.add.bind(this);
    this.containsObject = this.containsObject.bind(this);
  }

  componentDidMount() { }
  componentWillUnmount() { }
  remove = (hotel, id, e) => {
    this.setState({
      reload: !this.state.reload,
    });
    removeWithIdFromWishlist(hotel, id, e);
    if(this.props.refresh) {
      this.props.refresh();
    }
  };
  add = (hotel, item, e) => {
    this.setState({
      reload: !this.state.reload,
    });
    addToWishlist(hotel, item, e);
    if(this.props.refresh) {
      this.props.refresh();
    }
  };
  containsObject = (obj) => {
    return isInWishlist(obj);
  };

  render() {
    const itemCountInWishlist = isInWishlist(this.props.hotel, this.props.itemDetails);
    return (
      <>
        {(this.props.config.plan === "plan3" || this.props.config.plan === "plan2") ? (
          itemCountInWishlist ? (
            <div className="count-btn-wrap">
              <div
                className="fav-icon"
                onClick={(e) => this.remove(this.props.hotel, this.props.itemDetails.id, e)}
              >
                <ButtonIcon type={"remove"} color={this.props.config.theme_color} />
              </div>
              <div className="count" style={{ color: this.props.config.text_color }}>
                {itemCountInWishlist}
              </div>

              <div
                className="fav-icon minus"
                onClick={(e) => this.add(this.props.hotel, this.props.itemDetails, e)}
              >
                <ButtonIcon type={"add"} color={this.props.config.theme_color} />
              </div>
            </div>
          ) : (
              <div className="count-btn-wrap" style={{ color: this.props.config.text_color, border: "1px solid " + this.props.config.theme_color }}>

                <div
                  className=""
                  onClick={(e) => this.add(this.props.hotel, this.props.itemDetails, e)}
                >
                  add
              </div>
              </div>
            )
        ) : (
            <div>
              {itemCountInWishlist ? (
                <div
                  className="fav-icon"
                  onClick={(e) => this.remove(this.props.hotel, this.props.itemDetails.id, e)}
                >
                  <ButtonIcon type={"remove"} color={this.props.config.theme_color} />
                </div>
              ) : (
                  <div
                    className="fav-icon"
                    onClick={(e) => this.add(this.props.hotel, this.props.itemDetails, e)}
                  >
                    <ButtonIcon type={"add"} color={this.props.config.theme_color} />
                  </div>
                )}
            </div>
          )}
      </>
    );
  }
}
export default AddButton;
