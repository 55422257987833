import React from "react";
import { useHistory } from "react-router-dom";
import AddButton from "../AddButton/AddButton";

function Item(props) {
  let history = useHistory();

  const showDetail = (item) => {
    history.push(`/${props.hotel}/item/${item.slug}`, { back: true, item: item });
  }
  return (
    <div
      className="item"
      onClick={() => showDetail(props.itemDetails)}
    >
      <img src={props.itemDetails.food_image} alt="item" />
      <div className="item-desc">

        <h3>{props.config.entity_type !== "supermarket" && <img src={props.itemDetails.veg === true ? '/veg-square.svg' : '/non-veg-square.svg'} alt="food type" />}{props.itemDetails.name}</h3>

        <h3 style={{ color: props.config.text_color }}>
          {props.config.currency}
          {props.itemDetails.price}
        </h3>
        <div className="add-btn-block">
          <AddButton
            config={props.config}
            itemDetails={props.itemDetails}
            isInWishlist={props.isInWishlist}
            add={props.add}
            remove={props.remove}
            itemIndex={props.index}
            hotel={props.hotel}
            refresh={props.refresh}
          />
        </div>
      </div>
    </div>
  );
}
export default Item;
