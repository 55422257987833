import React from "react";

function ButtonIcon(props) {
  return (
    <>
      {props.type === "remove" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 91 91"
        >
          <g fill="none" fillRule="evenodd">
            <path
              fill={props.color}
              d="M77.672 13.328C69.073 4.73 57.66 0 45.5 0 33.34 0 21.927 4.73 13.328 13.328 4.73 21.927 0 33.34 0 45.5c0 12.16 4.73 23.573 13.328 32.172C21.927 86.27 33.34 91 45.5 91c12.16 0 23.573-4.73 32.172-13.328C86.27 69.073 91 57.66 91 45.5c0-12.16-4.73-23.573-13.328-32.172z"
              transform="matrix(-1 0 0 1 91 0)"
            />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M73.431 45.5c0-1.414-1.134-2.548-2.548-2.548l-22.834-.001h-5.095l-22.834-.002c-1.414 0-2.548 1.134-2.548 2.548 0 .693.28 1.347.734 1.8.454.454 1.094.747 1.8.734l22.835.001 5.108.014 22.835.001c1.413 0 2.547-1.133 2.547-2.547z"
              transform="matrix(-1 0 0 1 91 0)"
            />
          </g>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 91 91"
        >
          <g fill="none" fillRule="evenodd">
            <path
              fill={props.color}
              d="M77.672 13.328C69.073 4.73 57.66 0 45.5 0 33.34 0 21.927 4.73 13.328 13.328 4.73 21.927 0 33.34 0 45.5c0 12.16 4.73 23.573 13.328 32.172C21.927 86.27 33.34 91 45.5 91c12.16 0 23.573-4.73 32.172-13.328C86.27 69.073 91 57.66 91 45.5c0-12.16-4.73-23.573-13.328-32.172z"
              transform="matrix(-1 0 0 1 91 0)"
            />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M72.431 44.5c0-1.414-1.134-2.548-2.548-2.548l-22.834-.001-.001-22.834c0-1.414-1.134-2.548-2.548-2.548-1.414 0-2.547 1.134-2.547 2.547l.001 22.835-22.834-.002c-1.414 0-2.548 1.134-2.548 2.548 0 .693.28 1.347.734 1.8.454.454 1.094.747 1.8.734l22.835.001.001 22.835c0 .693.28 1.347.734 1.8.467.467 1.094.747 1.8.734 1.414 0 2.548-1.134 2.548-2.547l.025-22.808 22.835.001c1.413 0 2.547-1.133 2.547-2.547z"
              transform="matrix(-1 0 0 1 91 0)"
            />
          </g>
        </svg>
      )}
    </>
  );
}

export default ButtonIcon;
