import React, { useState, useEffect } from "react";
import { Route, useHistory, useParams } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import * as restServices from "../../utils/restUtils";
import Loader from '../Loader/Loader';
import { getData, isInWishlist, removeFromWishlist, removeData, storeData } from "../../utils/localStorageUtils";
import {
  withStyles,
} from '@material-ui/core/styles';
import "../../App.scss";
import ReactGA from 'react-ga';

const url = "place_order/";

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(TextField);

export const WishlistComponent = (props) => {
  let history = useHistory();
  const { slug } = useParams();

  const [isLoader, setLoader] = useState(false);
  const [config, setConfig] = useState({});
  const [table, setTable] = useState(null);
  const [wishlist, setWishlist] = useState([]);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [postOrderDetails, setPostOrderDetails] = useState({});
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [showNext, setShowNext] = useState(false);

  const [list, setList] = useState([]);
  const [form, setForm] = useState({
    name: "",
    phone: "",
    email: "",
    note_to_chef: "",
    vehicleInfo: "",
    optionalField: "",
  });

  const things = {};
  things.thing = [];

  useEffect(() => {
    setLoader(true);
    const path = history.location.pathname;
    if (path === `/${slug}/checkout`) {
      setShowNext(true);
    }
    else {
      setShowNext(false);
    }
    const table = getData(slug + "-table");
    if (table) {
      setTable(table.data);
    }
    const config = getData(slug + "-config");
    if (config) {
      setConfig(config.data);
    }
    else {
      history.push(`/${slug}/menu`);
    }
    const wishlist = getData(slug);

    if (wishlist) {
      setWishlist(wishlist.items);
    }
    setLoader(false);
    return () => {
      storeData("from", 1);
    }
  }, [slug, history.location.pathname]);

  useEffect(() => {
    things.thing = wishlist;
    setUniqueItems();
  }, [wishlist]);
  useEffect(() => {
    findTotal();
  }, [list]);

  const findTotal = () => {
    var total = 0;
    var items = [];
    var count = 0;
    var item = {};
    for (var i = 0; i < list.length; i++) {
      item = {};
      count = isInWishlist(slug, list[i]);
      total = total + list[i].price * count;
      item["id"] = list[i].id;
      item["quantity"] = count;
      items.push(item);
    }
    setTotal(total);
    setItems(items);
  };

  const containsObject = (obj) => {
    return isInWishlist(slug, obj);
  };
  const setUniqueItems = () => {
    const uniqueArray = things.thing.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return (
        index ===
        things.thing.findIndex((obj) => {
          return JSON.stringify(obj) === _thing;
        })
      );
    });
    setList(uniqueArray, findTotal());
  };
  const remove = (hotel, item, e) => {
    const count = isInWishlist(hotel, item);
    const removed = removeFromWishlist(hotel, item.id, e, count);
    if (removed) {
      const wishlist = getData(slug);
      setWishlist(wishlist.items);
    }
  };

  const postOrder = async () => {
    const requestBody = {
      items: items,
      description: form.vehicleInfo,
      restaurant_id: config.id,
      note_to_chef: form.note_to_chef,
      order_mode: table && table.code ? "dine_in" : "take_away",
      customer_name: form.name,
      customer_phone: form.phone,
      guest_count: 1,
    };
    if (table && table.code) {
      requestBody["table_id"] = table.code;
    }
    const postCall = await restServices.postData(url, requestBody);
    if (postCall !== undefined) {
      setPostOrderDetails(postCall.data);
      if (postCall.status === "ok") {
        setOrderSuccess(true);
        setList([]);
        setLoader(false);
      }
    }
  };

  const placeOrder = () => {
    //GA  
    ReactGA.event({
      category: 'PlaceOrder',
      action: 'PlaceOrder',
      label: slug,
      value: total
    });
  
    setLoader(true);
    postOrder();
    removeData(slug);
  };

  const placeWhatsAppOrder = () => {
    //GA  
    ReactGA.event({
      category: 'WhatsappOrder',
      action: 'WhatsappOrder',
      label: slug,
      value: total
    });

    setLoader(true);
  };

  const resetWhatsAppOrder = () => {
    setTimeout(() => {
      removeData(slug);
      setLoader(false);
    }, 2000);
  };


  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value
    });
  };

  const nextScreen = () => {
    setShowNext(!showNext);
    history.push(`/${slug}/checkout`);
  };

  return (
    <div className="wishlist-wrap animate dine-home">
      <div className="back-btn" id="back-btn" onClick={() => history.goBack()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"
            fill={config.theme_color}
          />
        </svg>
      </div>
      {isLoader ? <Loader message={'Please wait your order is processing...'} /> : (
        orderSuccess ? (
          <div className="wishlist-success-txt">
            <svg
              className="check-icon"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
              <path
                d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                fill={config.theme_color}
              />
            </svg>
            <div>{config.order_confirmation_text}</div>
            <div style={{ marginTop: "40px" }}>Order Number</div>
            <div
              style={{
                marginBottom: "40px",
                fontSize: "45px",
                color: config.text_color,
              }}
            >
              {postOrderDetails.order_number}
            </div>
            <div>Summary</div>
            <div>
              {postOrderDetails.items.map((item, index) => {
                return (
                  <div key={index} className="order-item">
                    <div>{item.name}</div>
                    <div className="qty-price">
                      {item.quantity > 1 ? (
                        <div className="sm-txt">
                          ({config.currency}
                          {item.unit_price}x{item.quantity})
                        </div>
                      ) : null}
                      <div>
                        {config.currency}
                        {item.unit_price * item.quantity}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="order-item">
                <div>Total</div>
                <div>
                  {config.currency}
                  {postOrderDetails.total_amount}
                </div>
              </div>
            </div>

            <div className="order-again" style={{ backgroundColor: config.theme_color }} onClick={() => history.goBack()}>
              Order Again
            </div>
          </div>
        ) : (
            <>
              <h2>{table ? table.name : "My cart"}</h2>
              {list.length > 0 ? (
                <>
                  {!showNext && (
                    <div className="item-list wishlist">
                      {list.map((item, index) => {
                        return (
                          <div className="item wishlist" key={index}>
                            <img src={item.food_image} alt="item" />
                            <div className="item-desc">
                              <h3>{item.name}</h3>

                              <div
                                className="fav-icon delete"
                                onClick={(e) => remove(slug, item, e)}
                                title="Remove"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 80 80"
                                >
                                  <g fill="none" fillRule="evenodd">
                                    <circle
                                      cx="39.556"
                                      cy="39.556"
                                      r="39.556"
                                      fill={config.theme_color}
                                    />
                                    <path
                                      fill="#FFF"
                                      fillRule="nonzero"
                                      d="M34.217 15c-1.24-.01-2.432.474-3.31 1.343-.878.87-1.368 2.052-1.361 3.283v1.463h-6.04c-2.263 0-4.175 1.667-4.468 3.895-.292 2.228 1.125 4.325 3.312 4.902v28.85c0 1.705.63 3.306 1.73 4.455 1.095 1.152 2.62 1.806 4.215 1.809h22.319c1.595-.003 3.12-.657 4.215-1.809 1.1-1.149 1.73-2.75 1.73-4.456v-28.85c2.187-.576 3.605-2.673 3.312-4.901-.293-2.228-2.205-3.894-4.468-3.895h-6.04v-1.463c.007-1.231-.483-2.413-1.36-3.283-.879-.869-2.071-1.353-3.311-1.343zM24.71 29.99H54.2v28.746c0 2.203-1.568 3.923-3.585 3.923H28.295c-2.017 0-3.586-1.72-3.586-3.923V29.99zm8.685 3.193c-.419 0-.758.52-.758 1.16v21.922c0 .64.34 1.16.758 1.16s.758-.52.758-1.16V34.342c0-.64-.34-1.16-.758-1.16zm12.121 0c-.418 0-.757.52-.757 1.16v21.922c0 .64.339 1.16.757 1.16.419 0 .758-.52.758-1.16V34.342c0-.64-.34-1.16-.758-1.16zm-6.06 0c-.419 0-.758.52-.758 1.16v21.922c0 .64.34 1.16.758 1.16s.757-.52.757-1.16V34.342c0-.64-.339-1.16-.757-1.16zm-15.949-9.75h31.897c1.172 0 2.123.943 2.123 2.107 0 1.164-.95 2.108-2.123 2.108H23.506c-1.172 0-2.123-.944-2.123-2.108 0-1.164.95-2.108 2.123-2.108zm10.711-6.09h10.475c.614-.01 1.207.226 1.642.657.436.43.678 1.017.67 1.627v1.463H31.905v-1.463c-.008-.61.234-1.197.67-1.627.435-.43 1.028-.667 1.642-.657z"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className="price-tag">
                                <div className="small-txt">
                                  ({config.currency}
                                  {item.price} x {isInWishlist(slug, item)})
                              </div>
                                <div>
                                  &nbsp;
                                {config.currency}
                                  {isInWishlist(slug, item) * item.price}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {config.plan === "plan3" && (
                        <div>
                          {!showNext && (
                            <>
                              <CssTextField
                                id="note_to_chef"
                                label="Note to the Chef"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                multiline
                                rows={2}
                                value={form.note_to_chef}
                                fullWidth
                                variant="outlined"
                                className="float-input" />
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {showNext && (
                    <div className="item-list ">
                      <div>

                        <CssTextField
                          id="name"
                          label="Name"
                          onChange={(e) => handleChange(e)}
                          value={form.name}
                          fullWidth
                          variant="outlined"
                          className="float-input"
                        />
                      </div>
                      <div>

                        <CssTextField
                          id="email"
                          label="Email"
                          onChange={(e) => handleChange(e)}
                          value={form.email}
                          fullWidth
                          variant="outlined"
                          className="float-input"
                        />
                      </div>
                      <div >

                        <CssTextField
                          type="number"
                          id="phone"
                          label="Phone"
                          onChange={(e) => handleChange(e)}
                          value={form.phone}
                          fullWidth
                          variant="outlined"
                          className="float-input"
                        />
                      </div>
                      {config.cart_optional_field_hint ? (
                        <>
                          <div>

                            <CssTextField
                              id="optionalField"
                              label={config.cart_optional_field_hint}
                              onChange={(e) => handleChange(e)}
                              value={form.optionalField}
                              fullWidth
                              variant="outlined"
                              className="float-input"
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  )}
                  <div className="fixed-to-bottom">
                    <div className="total-amount">
                      <div>Total:</div>{" "}
                      <div className="total-tax">
                        <div>
                          {config.currency}
                          {total.toFixed(2)}
                        </div>
                        {config.tax_text && <span>{config.tax_text}</span>}
                      </div>
                    </div>
                    {table && table.code ? (
                      <div
                        className="place-order-btn"
                        style={{ backgroundColor: config.theme_color }}
                        onClick={placeOrder}
                      >
                        Place order
                      </div>
                    ) : (
                        config.plan === "plan3" && (
                          <div
                            className={((config.plan === "plan3"
                              && showNext) && (!(form.name && (form.phone || form.email)))) ? 'place-order-btn disabled' : 'place-order-btn'}
                            style={{
                              backgroundColor: config.theme_color,
                            }}
                            onClick={
                              config.plan === "plan3"
                                ? showNext
                                  ? placeOrder
                                  : nextScreen
                                : placeOrder
                            }
                          >
                            {config.plan === "plan3"
                              ? showNext
                                ? "place order"
                                : "next"
                              : "Place order"}
                          </div>
                        )
                      )}

                    {((config.plan === "plan1" || config.plan === "plan2") && config.whatsapp_order_enabled === true) && (
                      <WhatsAppOrderComponent order={placeWhatsAppOrder} resetForm={resetWhatsAppOrder} config={config} list={list} containsObject={containsObject} total={total} />
                    )}
                  </div>
                </>
              ) : (
                  <div>Cart empty</div>
                )}
            </>
          )
      )}
    </div>
  );
}
export default WishlistComponent;

function WhatsAppOrderComponent(props) {
  const [whatsAppLink, setwhatsAppLink] = useState('');
  const postWhatsAppOrder = () => {
    // props.order();
    var i;
    var message = "*Order List*\n";
    var temp = "";
    var count = 0;
    for (i = 0; i < props.list.length; i++) {
      count = props.containsObject(props.list[i]);
      temp = `- ${props.list[i].name} (${props.config.currency}${props.list[i].price} x ${count}) = ${props.config.currency}${count * props.list[i].price}\n`;
      message = message.concat(temp);
      temp = "";
      count = 0;
    }
    message = message.concat(`\n Total = ${props.config.currency}${props.total}\n`);
    message = encodeURIComponent(message);
    setwhatsAppLink(`https://wa.me/${props.config.phone_number}?text=${message}`);
    props.resetForm();
  };

  const whatsAppOrder = () => {
    postWhatsAppOrder();
  }
  return (
    <div style={{ height: "57px", overflow: "hidden" }}>
      <div
        className="place-order-btn"
        style={{
          backgroundColor: props.config.theme_color,
        }}
        onClick={whatsAppOrder}
      >
        Order using WhatsApp
    </div>
      {whatsAppLink && <Route exact path="/:slug/cart" render={() => (window.location = whatsAppLink)} style={{
        opacity
          : "0"
      }} />}
    </div>
  );
}