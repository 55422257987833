import React from 'react';
import './Header.scss';
const headerItems = [{ "text": "Home", "link": "#home" }, { "text": "About", "link": "#" }, { "text": "Our Team", "link": "#" }, { "text": "Services", "link": "#" }, { "text": "Contact", "link": "#contact" }]
class DashboardHeader extends React.Component {

    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.resizeScreen = this.resizeScreen.bind(this);
        this.state = {
            isScrolled: false,
            openMenu: false,
            scrollFactor: 2,
            selectedItem: '#home',
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        // window.addEventListener('resize', this.resizeScreen);
        this.resizeScreen();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        // window.addEventListener('resize', this.resizeScreen);
    }

    handleScroll() {
        if (window.innerWidth > 767) {
            const scrollTop = window.scrollY;
            if (scrollTop > 100) {
                this.setState({
                    isScrolled: true,
                });
            }
            else {
                this.setState({
                    isScrolled: false
                });
            }
        }
        // this.updateMenu();
    }

    updateMenu() {
        for (let i = 0; i < headerItems.length; i++) {
            const x = headerItems[i].link;
            const y = x.slice(1);
            const menu = document.getElementById(y);
            const top = menu.getBoundingClientRect().top;
            if (top < (window.innerHeight / this.state.scrollFactor)) {
                this.setState({
                    selectedItem: '#' + y
                });
            }
        }
    }


    resizeScreen() {
        if (window.innerWidth > 767) {
            this.setState({
                openMenu: false,
                notMobile: true,
                scrollFactor: 2
            });
        }
        else {
            this.setState({
                notMobile: false,
                scrollFactor: 3
            });
        }
    }

    scrollView(id) {
        const anchor = document.querySelector(id);
        this.setState({ openMenu: false });
        anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    // toggleMenu = (() => {
    //     this.setState({
    //         openMenu: !this.state.openMenu
    //     });

    // });


    render() {
        return <div className={(this.state.isScrolled && this.state.notMobile) ? 'header scrolled' : 'header'}>
            <img src="/logo.svg" className="logo" alt="logo" />
            {/* <img src="/menu.svg" alt="menu" className="menu-icon" onClick={() => this.toggleMenu()} /> */}
            <img src="/menu.svg" alt="menu" className="menu-icon" />
            {/* {
                (window.innerWidth > 767 || this.state.openMenu) ? <ul>
                    {
                        headerItems.map((item, index) =>
                            <li key={index} className={this.state.selectedItem === item.link ? '' : ''}>
                                <span className="menu-item">{item.text}</span>
                            </li>
                        )
                    }
                </ul> : ''
            } */}
        </div>
    }
}

export default DashboardHeader;