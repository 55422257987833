import React from "react";

function Loader(props) {
  return (
    <div className="loader">
      <img src="/loader-1.jpg" alt="loader" />
      {props.message && <div className="loader-text">{props.message}</div>}
    </div>
  );
}

export default Loader;
