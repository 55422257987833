import React from "react";
// const headerItems = [{ "text": "Home", "link": "#home" }, { "text": "About", "link": "#" }, { "text": "Our Team", "link": "#" }, { "text": "Services", "link": "#" }, { "text": "Contact", "link": "#contact" }]

class DashboardFooter extends React.Component {
    
    componentDidMount() {
    }
    componentWillUnmount() {
    }

    render() {
        return (
            <div className="flex dashboard-footer">
                <div className="footer-logo">
                    <img src="/logo.svg" alt="" />
                    <p>&copy;2020, <a href="/">ordermydine.</a> All rights reserved</p>
                </div>
                {/* <div className="quick-menu">
                    <h4>QUICK MENU</h4>
                    <div className="flex">
                            <ul>
                            {
                                headerItems.map((item, index) =>
                                    <li key={index}  className={'active'}>
                                        <span className="menu-item">{item.text}</span>
                                    </li>
                                )
                            }
                            </ul>
                        <ul>
                        <li>documentation</li>
                            <li>terms of use</li>
                            <li>legal agreement</li>
                            <li>Privacy Policy</li>
                            <li>solutions</li>
                        </ul>
                    </div>
                </div> */}
                <div className="address">
                    <p>IGNITIF PVT. LTD.<br/>
                    Mobile 10X Building, 28/1650D, <br/>
                    Govt. CyberPark, Kozhikode,<br/>
                    Kerala, India. Pin: 673 016<br/>
                    </p>
                    <p>
                    <a className="phone" href="tel:+917034884456">+91 70348 84456</a><br/>
                    <a href="mailto: info@ordermydine.com">info@ordermydine.com</a>
                    </p>
                    </div>
                <div></div>
            </div>
        );
    }

}
export default DashboardFooter;